@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  --primary: #141213;
  --secondary: #ffffff;
  --light: #8a8989;
}

body {
  font-family: "Poppins";
}

.text-gradient {
  background: -webkit-linear-gradient(
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  opacity: 0.05;
  user-select: none;
}

.animation-pulse::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 100%;
  background-color: inherit;
  animation: 1s pulse infinite;
}

@keyframes pulse {
  0% {
    opacity: 1;
    scale: 1;
  }
  33% {
    opacity: 0.5;
    scale: 1.5;
  }
  66% {
    opacity: 1;
    scale: 1.2;
  }
  100% {
    opacity: 0;
    scale: 2;
  }
}

.animation-slider {
  animation: 10s slider linear infinite;
}

@keyframes slider {
  to {
    transform: translateX(-100%);
  }
}

.icon {
  @apply p-3 rounded-lg backdrop-blur-sm bg-white/5;
}

.vertical-text {
  writing-mode: vertical-rl;
  transform: rotate(180deg);
}
